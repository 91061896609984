import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import network from 'networking/network';
import { Loading } from '@gull';
import helperFunc from 'helpers/helperFunc';
import f7WebNetwork from 'networking/f7WebNetwork';



const HtmlEditor = React.forwardRef(({ onChangeContent, content }, ref) => {
    const editorRef = useRef(null);

    const [loadingVisible, setLoadingVisible] = useState(false);

    React.useImperativeHandle(ref, () => ({
        getEditorValue() {

            return editorRef.current.getContent();
        },
    }));
    const getLoadingPanel = () => {

        if (loadingVisible) {

            return (
                <Loading ></Loading>
            );
        } else {
            return null;
        }
    }


const onImage_Upload_Handler = (blobInfo, progress) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', 'postAcceptor.php');
  
    xhr.upload.onprogress = (e) => {
      progress(e.loaded / e.total * 100);
    };
  
    xhr.onload = () => {
      if (xhr.status === 403) {
        reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
        return;
      }
  
      if (xhr.status < 200 || xhr.status >= 300) {
        reject('HTTP Error: ' + xhr.status);
        return;
      }
  
      const json = JSON.parse(xhr.responseText);
  
      if (!json || typeof json.location != 'string') {
        reject('Invalid JSON: ' + xhr.responseText);
        return;
      }
  
      resolve(json.location);
    };
  
    xhr.onerror = () => {
      reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };
  
    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
  
    xhr.send(formData);
  });
  
 

    return (
        <>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                // value={content}
                // onEditorChange={(val) => {

                //     onChangeContent(val);
                // }} 
                initialValue={content}
                apiKey='6y3wyg8h3jidzlexcly9pg82o8920mz4sqwf43ngrjicpcci'
                
                init={{
                    paste_data_images:true,

                    selector: 'textarea#open-source-plugins',
                    plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                    editimage_cors_hosts: ['picsum.photos'],
                    menubar: 'edit view insert format tools table help',
                    toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist  | insertfile image media link codesample | table | charmap emoticons | fullscreen  preview',
                    toolbar_sticky: true,
                    toolbar_sticky_offset: 108,

                    autosave_ask_before_unload: true,
                    autosave_interval: '30s',
                    autosave_prefix: '{path}{query}-{id}-',
                    autosave_restore_when_empty: false,
                    autosave_retention: '2m',
                    image_advtab: true,
                    link_list: [
                        { title: 'Tıklayın İndirin', value: 'https://www.kamudunyasi.net/download/mobile' },
                        { title: 'Tıklayın İndirin', value: 'https://bisorumvarapp.com/mobile', }
                    ],
                    importcss_append: true,
                    file_picker_types: "file image media",
                    // images_upload_handler:onImage_Upload_Handler,
                    // images_upload_handler: function (blobInfo, success, failure, progress) {
                    //     console.log(blobInfo)
                    //     // For now just a placeholder image to test that we reach here when a user pastes an image
                    //     success("https://img.webmd.com/dtmcms/live/webmd/consumer_assets/site_images/article_thumbnails/other/cat_relaxing_on_patio_other/1800x1200_cat_relaxing_on_patio_other.jpg");
                    // },
                    file_picker_callback: (callback, value, meta) => {
                        console.log("file_picker_callback");
                        if (meta.filetype === 'file') {

                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "pdf/*");
                            input.onchange = function () {
                                var file = this.files[0];

                                var reader = new FileReader();
                                reader.onload = function () {
                                    var id = "blobid" + new Date().getTime();
                                    var blobCache = editorRef.current.editorUpload.blobCache;
                                    var base64 =  reader.result.split(",")[1];

                                    setLoadingVisible(true);
                                    var imageObj = {
                                        FileName: file.name.replace(".pdf",""),
                                        FileBase64: base64
                                    };
                              
                                    // network.post("blogpostadmin/saveBase64Pdf/", imageObj).then((result) => {
                                        f7WebNetwork.post("/admin/saveBase64Pdf/", imageObj).then((result) => {
                                        setLoadingVisible(false);

                                        if (result.isSuccess) {
                                            if (result.data.isSuccess) {
                                                var data = result.data;
                                                var savedFileName = data.savedFileName;
                                                var url = helperFunc.getNewsPdfFile(savedFileName);
    
                                                callback(url, { title: file.name });
                                            } else {
                                                alert(result.data.errorMessage);
                                            }
                                       
                                        } else {
                                            alert(result.errorMessage);
                                        }
                                    });
                                };
                                reader.readAsDataURL(file);
                            };
                            input.click();
                        }


                        if (meta.filetype === 'media' || meta.filetype === 'image') {
                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");
                            input.onchange = function () {
                                var file = this.files[0];

                                var reader = new FileReader();
                                reader.onload = function () {
                                    var id = "blobid" + new Date().getTime();
                                    var blobCache = editorRef.current.editorUpload.blobCache;
                                    var base64 = reader.result.split(",")[1];
                                    callback("data:image/png;base64," + base64, { title: file.name });
                                };
                                reader.readAsDataURL(file);
                            };
                            input.click();

                        }
                    },
                    templates: [
                        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                        { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                    ],
                    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                    height: 800,
                    image_caption: true,
                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                    noneditable_class: 'mceNonEditable',
                    toolbar_mode: 'sliding',
                    contextmenu: 'link image table',
                    
                    skin: 'oxide',
                    content_css: 'default',

                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',

                    // here where it convers from video to object
                    init_instance_callback: function (editor) {

                        editor.on('BeforeSetContent', function (e) {
                            console.log('before - ', e.content);

                            if (e.content.indexOf("video ") > 0) {
                                if (e.content.indexOf(".pdf") > 0) {
                                    e.content = e.content.replace("<video", "<div");

                                    var width = e.content.match("width=\"(.*)\" height");
                                    var height = e.content.match("height=\"(.*)\" controls");
                                    //console.log('width - ', width, ' height - ', height);

                                    e.content = e.content.replace("controls=\"controls\"", "");

                                    e.content = e.content.replace("<source", "<object");
                                    e.content = e.content.replace("src=", "data=");

                                    e.content = e.content.replace("</video", "</div");
                                    e.content = e.content.replace("/>", "type=\"application/pdf\" width=\"" + width[1] + "\" height=\"" + height[1] + "\"  ></object>");
                                }

                                console.log('after - ', e.content);
                            }
                        });
                    },


                }}
            />
            {getLoadingPanel()}
        </>
    );
});

export default HtmlEditor;


const globalTypes ={

    AppServicesAssetsPath: {

        UserImagesPath: "ProjectImages/UserProfiles/",
        PostImagesPath: "ProjectImages/PostImages/",
        SagMansetPath: "ProjectImages/313x331/",
        avatarImages: "ProjectImages/avatarImages/",
        newsPdfFile: "ProjectImages/newsdocuments/",
        CommentImagesPath: "ProjectImages/CommentImages/",
        adsSettingsPath: "ProjectImages/mobileAdSetting.json",

    },
 
    serviceUrl : "https://www.kamudunyasi.net/",
    // serviceUrl : "https://localhost:5001/",
    webProjectUrl: "https://www.kamudunyasi.net",
    bisorumvarService : "https://shareappprod.bilgibankasi1.com",
    getServiceFullUrl (){
        return this.serviceUrl + "api/";
    },

    dayList :[
        "Pazar",
        "Pazartesi",
        "Salı",
        "Çarşamba",
        "Perşembe",
        "Cuma",
        "Cumartesi", 
    ],
    loadingTypes :{
        categoryNews : 1,
        breakingNews : 2,
        homePageSlider : 3
    },
    loginUserType : {
        superAdmin : 1,
        admin : 2,
        editor :3
    },
    htmlBlockType : {
        textInput : 1,
        imageArea : 2, 
        embedIframe : 3, 
    },
    paginationOptions : {
        // custom: true,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: "İlk",
        prePageText: "Geri",
        nextPageText: "İleri",
        lastPageText: "Son",
        nextPageTitle: "İlk",
        prePageTitle: "Önceki ",
        firstPageTitle: "Sonraki",
        lastPageTitle: "Son",
        showTotal: true, 
      },

      postSplitTags : {
         textSplitTag : "<!-- finans:paragraph -->",
         imageSplitTag : "<!-- finans:img -->",
         embedHtmlSplitTag : "<!-- finans:social -->",
         imageBottomTextSplitTag : "<!-- finans:img_bottom -->",
      },
      htmlDefaultBlock : {
        blockType :1,
        blokcHtml : "<p></p>",
        blockImageType : -1,
        blockImageName : "",
        blockImageFile : "",
        blockImageAlt : "",
        blockImageBottomText : "",
        blockDisplayOrder : 0,
        id : 0, 
      },
      mainImageType : {
        fromRepository: 1,
        fromUrl: 2,
        fromUpload: 3,
        fromBase64 : 4
    },
    LessonGroupList: [
        { label: "Yok", value: -1 },
        { label: "KPSS", value: 1 },
        { label: "YKS", value: 4 },
        { label: "DGS", value: 5 },
        { label: "LGS", value: 6 },
        { label: "ALES", value: 3 },
        { label: "Yabancı Dil", value: 2 },
    ],
}

export default globalTypes;
